/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import flatten from "flat"
import { IntlProvider, addLocaleData } from "react-intl"
import debounce from "debounce"

import SocialNetwork from "./social-network"

// Locale data
import zhData from "react-intl/locale-data/zh"
import enData from "react-intl/locale-data/en"
import jaData from "react-intl/locale-data/ja"
import esData from "react-intl/locale-data/es"
import ruData from "react-intl/locale-data/ru"
// Messages
import zh from "../locales/zh.json"
import en from "../locales/en.json"
import ja from "../locales/ja.json"
import es from "../locales/es.json"
import ru from "../locales/ru.json"

import "bootstrap/dist/css/bootstrap.css"
import "./layout.css"

const messages = { zh, en, ja, es, ru }

addLocaleData([...zhData, ...enData, ...jaData, ...esData, ...ruData])

class Main extends Component {
  constructor(props) {
    super(props)
    this.handleResize = debounce(this.handleResize.bind(this), 200)
  }
  handleResize() {
    const width =
      window.innerWidth ||
      window.document.documentElement.clientWidth ||
      window.document.body.clientWidth
    if (width > 1080) {
      window.location = `${process.env.MASS_DESKTOP}${window.location.pathname}`
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }
  render() {
    return <main>{this.props.children}</main>
  }
}

const Layout = ({ locale, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <IntlProvider
        locale={locale}
        messages={flatten(messages[locale])}
        textComponent={React.Fragment}
      >
        <Fragment>
          <div id="mainContainer">
            <Main>{children}</Main>
            <SocialNetwork />
            <footer>Copyright &#169; massnet.org All rights reserved.</footer>
          </div>
        </Fragment>
      </IntlProvider>
    )}
  />
)

Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
