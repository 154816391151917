import React, { Component } from "react"
import PropTypes from "prop-types"
import { Location } from "@reach/router"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBlog from "../components/header-blog"
import BlogList from "../components/blog-list"
import BlogContent from "../components/blog-content"
class BlogPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blogId: ''
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let id = this.props.location.pathname.match(/([^\/]*)\/*$/)[1]
    if (prevState.blogId !== id) {
      this.setState({
        blogId: id
      })
    }
    // todo: get blog detail
  }
  componentDidMount() {
    let id = this.props.location.pathname.match(/([^\/]*)\/*$/)[1]
    this.setState({
      blogId: id === 'blog' ? '' : id
    })
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <HeaderBlog />
        {
          this.state.blogId && this.state.blogId != '' &&this.state.blogId != 'blog' ?
            <BlogContent blogId={this.state.blogId}/> : <BlogList />
        }
        <SEO
          title="Blog | MASS Blockchain Consensus Engine" keywords={[`MASS Blockchain Consensus Engine`]}
          lang={this.props.pageContext.locale}
        />
      </Layout>
    )
  }
}

BlogPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default BlogPage
