import React, { Component } from "react"
import { Animated } from "react-animated-css"
import { StaticQuery, graphql } from "gatsby"

import "animate.css"
import "./social-network.css"

export default class SocialNetwork extends Component {
  constructor() {
    super()
    this.state = {
      showTwitter: false,
      showTelegram: false,
      showReddit: false,
      showDiscord: false,
    }
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            twitterIco: file(relativePath: { eq: "twitter.svg" }) {
              publicURL
            }
            telegramIco: file(relativePath: { eq: "telegram.svg" }) {
              publicURL
            }
            redditIco: file(relativePath: { eq: "reddit.svg" }) {
              publicURL
            }
            discordIco: file(relativePath: { eq: "discord.svg" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <div className="social-floatbox">
            <div
              class="box"
              onMouseEnter={() => this.setState({ showTwitter: true })}
              onMouseLeave={() => this.setState({ showTwitter: false })}
            >
              <img src={data.twitterIco.publicURL} />
              {this.state.showTwitter && (
                <Animated
                  animationIn="fadeInRight"
                  animationOut="fadeOut"
                  isVisible={this.state.showTwitter}
                >
                  <a
                    className="text"
                    href="https://twitter.com/massnetorg"
                    target="_blank"
                  >
                    @massnet.org
                  </a>
                </Animated>
              )}
            </div>
            <div
              class="box"
              onMouseEnter={() => this.setState({ showTelegram: true })}
              onMouseLeave={() => this.setState({ showTelegram: false })}
            >
              <img src={data.telegramIco.publicURL} />
              {this.state.showTelegram && (
                <Animated
                  animationIn="fadeInRight"
                  animationOut="fadeOut"
                  isVisible={this.state.showTelegram}
                >
                  <a
                    className="text"
                    href="https://t.me/massnetorg"
                    target="_blank"
                  >
                    @massnet.org
                  </a>
                </Animated>
              )}
            </div>
            <div
              class="box"
              onMouseEnter={() => this.setState({ showDiscord: true })}
              onMouseLeave={() => this.setState({ showDiscord: false })}
            >
              <img src={data.discordIco.publicURL} />
              {this.state.showDiscord && (
                <Animated
                  animationIn="fadeInRight"
                  animationOut="fadeOut"
                  isVisible={this.state.showDiscord}
                >
                  <a
                    className="text"
                    href="https://discord.gg/Ffk4VFS"
                    target="_blank"
                  >
                    MASSNETORG
                  </a>
                </Animated>
              )}
            </div>
            {/* <div
              class="box"
              onMouseEnter={() => this.setState({ showReddit: true })}
              onMouseLeave={() => this.setState({ showReddit: false })}
            >
              <img src={data.redditIco.publicURL} />
              {this.state.showReddit && (
                <Animated
                  animationIn="fadeInRight"
                  animationOut="fadeOut"
                  isVisible={this.state.showReddit}
                >
                  <div className="text">/r/massnet.org</div>
                </Animated>
              )}
            </div> */}
          </div>
        )}
      />
    )
  }
}
