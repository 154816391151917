import React from "react"
import { FormattedMessage } from "react-intl"
import axios from "axios"
import LocalizedLink from "./localized-link"
import "./blog-content.css"

class BlogContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
      lang: 'en'
    }
  }
  setLang= () =>{
    let lang  = 'en'
    if(typeof window !== "undefined"){
      let pathLang = window.location.pathname.split('/')[1]
      if(pathLang){
        lang = pathLang || 'en'
      }
    }
    this.setState({
      lang: lang
    })
  }
  componentWillMount() {
    this.setLang()
  }
  componentDidMount() {   
    axios
      .get(`${process.env.API_ADDRESS}/posts?lang=${this.state.lang == 'zh' ? 'zh': 'en'}`)
      .then(resp => {
        this.setState({
          posts: resp.data,
        })
      })
      .catch(e => {
        console.log(e)
      })
  }
  formatterTimer = (time) => {
    var d = new Date(time);
    let month = d.getMonth() + 1
    month = month < 10 ? '0' + month : month
    let date = d.getDate()
    date = date < 10 ? '0' + date : date
    return d.getFullYear() + '/' + month + '/' + date
  }
  render() {
    return (
      <div className="container blog-detail-container">
        <div className="blog-detail-left">
          {this.state.posts.map(post => {
            if (post.slug === this.props.blogId || post._id === this.props.blogId) {
              return (
                <div key={post._id}>
                  <div className="blog-title">
                    {post.title}
                  </div>
                  <div className="blog-content-time">
                    <span>
                      <FormattedMessage id="org.massnet.components.blog.time" />{" "}
                      {this.formatterTimer(post.createdAt)}
                    </span>
                  </div>
                  <div className="blog-detail-content" dangerouslySetInnerHTML={{ __html: post.content }}></div>
                </div>
              )
            }
          })}
        </div>
        <div className="blog-other-list">
          <div className="blog-other-title">
            <h3><FormattedMessage id="org.massnet.components.blog.blog_list" /></h3>
          </div>
          <ul className="other-list">
            {this.state.posts.map(post => {
              return (
                <li key={post._id}>
                  <LocalizedLink
                    className={post.slug === this.props.blogId || post._id === this.props.blogId ? 'active' : ''}
                    to={`/blog/${post.slug || post._id}`}>
                    {post.title}
                  </LocalizedLink>
                </li>
              )
            })}
          </ul>
        </div >
      </div>
    )
  }
}
export default BlogContent
